import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IMAGE_UPDATE_API_URL, APPROVE_IMAGE_API_URL } from './Constant';
import './EditImage.scss';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';

const Edit = () => {
  const [selectImage, setSelectImage] = useState(null);
  const [editedTags, setEditedTags] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
  const [authKey, setAuthKey] = useState('');

  useEffect(() => {
    const storedImage = JSON.parse(localStorage.getItem('selectImage'));
    setSelectImage(storedImage);
    setEditedTags(storedImage.tags);
    setEditedDescription(storedImage.description);
    let authKey = localStorage.getItem('authKey');
    setAuthKey(authKey);
  }, []);

  const handleTagsChange = (event) => {
    setEditedTags(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  };

  const sendUpdateRequest = () => {
    const updatedImage = {
      ...selectImage,
      tags: editedTags,
      description: editedDescription,
    };

    const payload = {
      id: updatedImage.imageId,
      tags: updatedImage.tags,
      description: updatedImage.description,
    };
    const axiosConfig = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        authKey,
      },
    };

    axios
      .post(IMAGE_UPDATE_API_URL, payload, axiosConfig)
      .then((response) => {
        console.log('API response:', response.data);
      })
      .catch((error) => {
        console.error('API error:', error);
      });
  };

  const handleApprove = (id) => {
    const axiosConfig = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        authKey,
      },
    };

    axios
      .post(APPROVE_IMAGE_API_URL, { id }, axiosConfig)
      .then((response) => {
        toast.success('Image approved', {
          position: toast.POSITION.TOP_LEFT,
        });

        // Optionally, you can redirect to another page or perform other actions upon approval.
        // For example, you can navigate to a different page using React Router:
        // history.push('/approved-images');

        console.log('Image approved:', response.data);
      })
      .catch((error) => {
        console.error('Error approving image:', error);
        toast.error('Error approving image', {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  const handleUpdate = () => {
    sendUpdateRequest();
  };

  return (
    <div className='images-cart'>
      {selectImage && (
        <div className='images-detail'>
          <img
            className='image-url'
            src={selectImage.imagedownloadableUrl}
            alt='Downloadable'
          />
          <div className='input-change-container'>
            <input
              id='tags'
              className='tag-input'
              value={editedTags}
              onChange={handleTagsChange}
              placeholder='Tags'
            />
            <input
              id='description'
              className='description-input'
              value={editedDescription}
              onChange={handleDescriptionChange}
              placeholder='Description'
            />
          </div>
          <button className='update' onClick={handleUpdate}>
            Update
          </button>
          <button className='edit-image-approve' onClick={() => handleApprove(selectImage.imageId)}>Approve</button>
        </div>
      )}
    </div>
  );
};

export default Edit;
