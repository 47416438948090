import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'; 
import { CUSTOMER_ORDER_API_URL } from './Constant';
import axios from 'axios';
import './OrderImage.scss';

const OrderImages = () => {
  const selectedOrder = JSON.parse(localStorage.getItem('selectedOrder'));

  const [selectedImages, setSelectedImages] = useState([]);

  useEffect(() => {
    if (selectedOrder) {
      const selectedOrderId = selectedOrder.orderId;
      axios
        .post(CUSTOMER_ORDER_API_URL, { orderId: selectedOrderId })
        .then((response) => {
          const order = response.data.result.find(
            (order) => order.orderId === selectedOrderId
          );
          if (order) {
            setSelectedImages(order.imagesId);
          }
        })
        .catch((error) => {
          console.error('Error fetching image data:', error);
        });
    }
  }, []);

  return (
    <div className="image-viewer-container">
      
      <div className="card-container">
        {selectedImages.map((image) => (
          <div key={image._id} className="custom-card">
            <img
              className="card-image"
              src={image.compressedUrl}
              alt={`Image ${image._id}`}
            />
            <div className="tags-container">
              {image.tags &&
                image.tags.map((tag, tagIndex) => (
                  <span key={tagIndex} className="tag">
                    {tag}
                  </span>
                   
                ))}
            </div>
          </div>
        ))}
      </div>
     <button className='back-button'><Link to="/order">Back to Order Management</Link></button>
    </div>
  );
};

export default OrderImages;
