import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './CustomerForgotpass.scss';
import axios from 'axios';
import { CUSTOMER_OTP_API_URL } from './Constant';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [errors, setErrors] = useState({
    email: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEmail(value); 
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

    if (!email.trim()) {
      newErrors.email = '*Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      newErrors.email = 'Invalid email format';
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    } else {
      // Send the email to the API
      axios
        .post(CUSTOMER_OTP_API_URL, { email })
        .then((response) => {
          alert('Email sent successfully:', response.data);
        })
        .catch((error) => {
          console.warn('Error sending email:', error);
        });
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="forgot-password-box">
        <h6>FORGET PASSWORD</h6>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <div className="label-input-container">
              <label htmlFor="email"></label>
              <input
                type="email"
                value={email}
                onChange={handleChange}
                className="input"
                placeholder="Enter your email"
              />
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
          </div>

          <button type="submit" className="submit-btn">
            Submit
          </button>

          <Link to="/signup" className="home">
            Home
          </Link>
        </form>
      </div>
    </div>
  );
};

export default ForgotPassword;
