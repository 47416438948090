export const BASE_URL = ` https://skincraft.vercel.app`;
export const LOGIN_API_URL = `${BASE_URL}/api/admin/login`;
export const IMAGE_UPLOAD_API_URL = `${BASE_URL}/api/images/upload?tags=`;
export const IMAGES_API_URL = `${BASE_URL}/api/images/managerPending`;
export const HOME_API_URL = `${BASE_URL}/api/images/manager`;
export const APPROVE_IMAGE_API_URL=`${BASE_URL}/api/images/approveImage`;
export const REJECT_IMAGE_API_URL = `${BASE_URL}/api/images/rejectImage`;
export const CART_API_URL = `${BASE_URL}/api/images/storeUserInfo`;
export const CUSTOMER_LOGIN_API_URL = `${BASE_URL}/api/customer/login`;
export const CUSTOMER_SIGNUP_API_URL = `${BASE_URL}/api/customer/signup`;
export const CUSTOMER_ORDER_API_URL = `${BASE_URL}/api/images/managerForImageDownload`;
export const CUSTOMER_OTP_API_URL = `${BASE_URL}/api/customer/sendOtp`;
export const CUSTOMER_UPDATE_API_URL = `${BASE_URL}/api/customer/updatePassword`;
export const IMAGE_UPDATE_API_URL = `${BASE_URL}/api/images/update`;

