import React, { useState } from 'react';
import './Signup.scss';
import { CUSTOMER_SIGNUP_API_URL, CUSTOMER_LOGIN_API_URL } from './Constant'; 
import axios from 'axios';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom'; 

const Signup = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    confirmPassword: '',
  });

  const [showLogin, setShowLogin] = useState(false);
  const navigate = useNavigate()


 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleLoginSuccess = (authKey) => {
    
    localStorage.setItem('customerauthKey', authKey);

    navigate('/')
    window.location.reload()
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!showLogin) {
      axios
        .post(CUSTOMER_SIGNUP_API_URL, formData)
        .then((response) => {
          toast.success('Registration successful!', {
            position: toast.POSITION.TOP_LEFT,
          });
        })
        .catch((error) => {
          toast.error('Registration unsuccessful!', {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    } else {
      const loginData = {
        email: formData.email,
        password: formData.password,
      };

      axios
        .post(CUSTOMER_LOGIN_API_URL, loginData)
        .then((response) => {
          
          const authKey = response.data.result.authKey;

          if (authKey) {
           
            handleLoginSuccess(authKey);

            toast.success('Login successful!', {
              position: toast.POSITION.TOP_LEFT,
            });
          } else {
           
            toast.error('Login failed. AuthKey is missing in the response.', {
              position: toast.POSITION.TOP_LEFT,
            });
          }
        })
        .catch((error) => {
          toast.error('Login failed. Please check your credentials.', {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    }
  };

  return (
    <body className='sign-up-body'>
    <div className="signup-container">
      <h6 className='form-login-sign'>{showLogin ? 'Login Form' : 'Signup Form'}</h6>
      <form onSubmit={handleSubmit}>
        {showLogin ? (
          <div>
            <div className="group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="group">
              <input
                type="password"
                id="password"
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
            {/* <div className="forgot-password">
            <Link to="/forgot-pass">Forgot Password?</Link>
          </div> */}
          </div>
        ) : (
          <div>
            <div className="group">
              <input
                type="text"
                id="name"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="group">
              <input
                type="email"
                id="email"
                name="email"
                placeholder="Email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="group">
              <input
                type="password"
                id="password"
                placeholder="Password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>
          </div>
        )}

        <div className="group">
          {!showLogin && (
            <input
              type="password"
              id="confirmPassword"
              placeholder="Confirm Password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
            />
          )}
        </div>
        <button className="sign-up" type="submit">
          {showLogin ? 'Login' : 'Signup'}
        </button>
        <p className='or-p'>or</p>
        <button
          className="login-button"
          type="button"
          onClick={() => setShowLogin(!showLogin)}
        >
          {showLogin ? ' Signup' : 'Login'}
        </button>
      </form>
    </div>
    </body>
  );
};

export default Signup;
