import React, { useEffect, useState } from 'react';
import './OrderManagement.scss';
import axios from 'axios';
import { CUSTOMER_ORDER_API_URL } from './Constant';
import { Link,useNavigate } from 'react-router-dom';

const OrderManagement = () => {
  const [imageData, setImageData] = useState([]);
  const navigate=useNavigate();

  useEffect(() => {
    axios.post(CUSTOMER_ORDER_API_URL)
      .then((response) => {
        setImageData(response.data.result);
      })
      .catch((error) => {
        console.error('Error fetching image data:', error);
      });
  }, []);

  const viewImages = (orderId) => {
    const selectedOrder = imageData.find((image) => image.orderId === orderId);
    console.log('order:', selectedOrder);
  
    if (selectedOrder) {
      localStorage.setItem('selectedOrder', JSON.stringify(selectedOrder));
      navigate(`/order-images`);
    } else {
      console.log('Order not found for orderId:', orderId);
    }
  };

  return (
    <div className="order-management-container">
      <table className="order-table">
        <thead>
          <tr>
            <th>Order ID</th>
            <th>Name</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {imageData.map((image) => (
            <tr key={image._id}>
              <td>{image.orderId}</td>
              <td>{image.name}</td>
              <td className='email-order'>{image.email}</td>
              <td>
              <button className="view-images-button" onClick={() => viewImages(image.orderId)}>View Images</button>
              </td>
            </tr>
            
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderManagement;
