import HomeOne from "./HomeOne";
// import HomeTwo from "./HomeTwo";
// import HomeThree from "./HomeThree";
import AboutOne from "./AboutOne";
import AboutTwo from "./AboutTwo";
import ComingSoon from "./ComingSoon";
import Community from "./Community";
import FAQ from "./FAQ";
// import Page404 from "./404";
import RoadMapOne from "./RoadMapOne";
import RoadMapTwo from "./RoadMapTwo";
import NftItem from "./NftItem";
import NftItemDetails from "./NftItemDetails";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import Login from "./Login";
import Contact from "./Contact"
import TeamDetail from "./TeamDetail";
import ImageUpload from "./ImageUpload";
import Approval from "./Approval";
import Shoppingcart from "./Shoppingcart"
import ImageDetail from "./ImageDetail";
import Signup from "./Signup";
import OrderManagement from './OrderManagement';
import CustomerManagement from './CustomerManagement';
import EditImage from './EditImage';
import CustomerLogin from './CustomerLogin';
import CustomerForgotpass from './CustomerForgotpass';
import CustomerPasswordUpdate from './CustomerPasswordUpdate';
import OrderImages from './OrderImages';
import EditPage from './EditPage';
import HomePageEdit from './HomePageEdit';


const routes = [
  { path: '/', component: <HomeOne />},
  // { path: '/home-v2', component: <HomeTwo />},
  // { path: '/home-v3', component: <HomeThree />},
  { path: '/about-v1', component: <AboutOne />},
  { path: '/about-v2', component: <AboutTwo />},
  { path: '/coming-soon', component: <ComingSoon />},
  { path: '/community', component: <Community />},
  { path: '/faq', component: <FAQ />},
  // { path: '/404', component: <Page404 />},
  { path: '/road-map-v1', component: <RoadMapOne />},
  { path: '/road-map-v2', component: <RoadMapTwo />},
  { path: '/nft-item', component: <NftItem />},
  { path: '/nft-item-details', component: <NftItemDetails />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/contact', component: <Contact />},
  { path:'/login',component:<Login />},
  { path: '/team-details', component: <TeamDetail />},
  { path: '/image-upload',component:<ImageUpload />},
  {path:'/approval',component:<Approval />},
  {path:'/shopping',component:<Shoppingcart />},
  {path:'/imagedetail',component:<ImageDetail />},
  {path:'/signup',component:<Signup/>},
  {path:'/order',component:<OrderManagement />},
  {path:'/customer',component:<CustomerManagement />},
  {path:'/edit',component:<EditImage />},
  {path:'/customer-login',component:<CustomerLogin />},
  {path:'/forgot-pass',component:<CustomerForgotpass />},
  {path:'/password-update', component:<CustomerPasswordUpdate />},
  {path:'/order-images',component:<OrderImages />},
  {path:'/home-edit',component:<EditPage />},
  {path:'/homepage-edit',component:<HomePageEdit />},


]
export default routes;

