import React, { useEffect, useState } from 'react';
import './ShoppingCart.scss';
import DownloadPopup from './DownloadPopup';

const ShoppingCart = () => {
  const [cartItems, setCartItems] = useState(JSON.parse(localStorage.getItem('shoppingCart')) || []);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [downloadSuccess, setDownloadSuccess] = useState(false);

  const handleRemoveFromCart = (index) => {
    const updatedCart = cartItems.filter((_, i) => i !== index);
    localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
    setCartItems(updatedCart);
  };
     
  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };
   
  
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDownload = () => {
    setDownloadSuccess(true);
  };

  useEffect(() => {
    if (downloadSuccess) {
      const timeout = setTimeout(() => {
        setDownloadSuccess(false);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [downloadSuccess]);  

  return (
    <body className='shop'>
      <div className="shopping-div">
        <h6 className='shop-cart'> Shopping Cart</h6>
        <button className="down-load" onClick={handleOpenPopup}>
          Download Images
        </button> 
        <ul className="grid-container">
          {cartItems.map((item, index) => (
            <li key={item._id} className="grid-item">
              <img
                className="shopping"
                src={item.imagedownloadableUrl}
                alt={item.title}
              />
              <button
                className="remove-images"
                onClick={() => handleRemoveFromCart(index)}
                >
                Remove
              </button>

            </li>
          ))}
        </ul>
      </div>
      {isPopupOpen && (
        <DownloadPopup
          isOpen={isPopupOpen}
          onClose={handleClosePopup}
          onDownload={handleDownload}
          cartItems={cartItems}
        />
      )}
      {downloadSuccess && (
        <div className="success-message">
          {/* Download Successful! */}
        </div>
      )}
    </body>
  );
};

export default ShoppingCart;
