import React, { useState } from 'react';
import {  useNavigate } from 'react-router-dom'; 
// import './css/UpdatePassword.css';
import axios from 'axios';
import { CUSTOMER_UPDATE_API_URL } from './Constant';

const UpdatePassword = () => {
 
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({
    otp: '',
    password: '',
    confirmpassword: '',
  });
 
  const navigate = useNavigate();
 

  const handleSubmit = (e) => {
    e.preventDefault();
    const newErrors = {};

   
  

   

    if (!password.trim()) {
      newErrors.password = '*Password is required';
    }

    if (!confirmpassword.trim()) {
      newErrors.confirmpassword = '*Confirm Password is required';
    } else if (password !== confirmpassword) {
      newErrors.confirmpassword = '*Passwords do not match';
    }

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
       
      const data = {
       
        otp: otp,
        password: password,
        confirmpassword:confirmpassword,
      };

      axios.post(CUSTOMER_UPDATE_API_URL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.success) {
            alert('Password updated successfully.');
            navigate('/login'); 
           
          } else {
            alert('Password update failed. check your credentials or OTP expired');
          }
        })
        .catch((error) => {
          console.error('Error:', error);
          alert('An error occurred. Please try again later.');
        });
    }
  };

  return (
    <div className="update-password-container">
      
      <div className="update-password-box">
        <form onSubmit={handleSubmit}>
         
          <div className="form-group">
            <div className="label-input-element">
              <label htmlFor="otp"></label>
              <input
                type="text"
                name="otp"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                className="input"
                placeholder=" OTP"
              />
              {errors.otp && <span className="error">{errors.otp}</span>}
            </div>
          </div>
          <div className="form-group">
            <div className="label-input-element">
              <label htmlFor="password"></label>
              <input
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="input"
                placeholder="New Password"
              />
              {errors.password && <span className="error">{errors.password}</span>}
            </div>
          </div>
          <div className="form-group">
            <div className="label-input-element">
              <label htmlFor="confirmpassword"></label>
              <input
                type="password"
                name="confirmpassword"
                value={confirmpassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="input"
                placeholder="Confirm New Password"
              />
              {errors.confirmpassword && <span className="error">{errors.confirmpassword}</span>}
            </div>
          </div>
          <button type="submit" className="updt-btn">
            Update Password
          </button>
        </form>
      </div>
   </div>
  );
};

export default UpdatePassword;