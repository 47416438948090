
import {React , useEffect} from 'react';
import AOS from 'aos';
import { Route, Routes,useLocation } from 'react-router-dom';
import Header from './components/header';
// import Footer from './components/footer';
import '../src/assets/font/font-awesome.css'
import routes from './pages';
import Page404 from './pages/404';
import { CookieBanner } from '@keepist/react-gdpr-cookie-banner';

function App() {
    const location = useLocation();
    const isLogin = location.pathname === '/login';
    useEffect(() => {
        AOS.init({
          duration : 2000
        }); 
      }, []);
      

    return (
        <>
          {/* {!isLogin && <Header />} */}
            <Header />
            <CookieBanner
      message="Cookie banner message"
      onAcceptPreferences = {() => { 
        // load your preferences trackers here
      }}
      onAcceptStatistics = {() => {
        // load your statistics trackers here
      }}
      onAcceptMarketing = {() => {
        // load your marketing trackers here
      }}
    />
           

            <Routes>

                {
                    routes.map((data,idx) => (
                        <Route key={idx} path={data.path} element={data.component} exact />
                    ))
                }

                <Route path='*' element={<Page404 />} />
               
            </Routes>

            {/* <Footer /> */}
        </>
    );
}

export default App;
