import React, { useState } from 'react';
import axios from 'axios';
import './ImageUpload.scss';
import { IMAGE_UPLOAD_API_URL } from './Constant';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 
import { XSquare } from 'react-bootstrap-icons';
import { useNavigate } from 'react-router-dom';

const ImageUpload = ({ onUpload }) => {
  const [inputText, setInputText] = useState('');
  const [inputList, setInputList] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [description, setDescription] = useState(''); 
  const [email, setEmail] = useState(''); 
  const navigate = useNavigate();

  const handleInputChange = (event) => {
    setInputText(event.target.value);
  };

  const handleAddClick = () => {
    if (inputText.trim() !== '') {
      const updatedList = [...inputList, inputText.trim()];
      setInputList(updatedList);
      setInputText('');
    } else {
      toast.error('Please enter a valid tag', {
        position: toast.POSITION.TOP_LEFT,
      });
    }
  };

  const handleRemoveClick = (index) => {
    const updatedList = inputList.filter((_, i) => i !== index);
    setInputList(updatedList);
  };

  const handleFileChange = (event) => {
    const files = event.target.files;

    for (let i = 0; i < files.length; i++) {
      const file = files[i];

      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedFiles([...selectedFiles, file]);
        setImagePreviews([...imagePreviews, reader.result]);
      };
      reader.readAsDataURL(file);
    }
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailRegex.test(email);
  };

  const handleUpload = () => {
    if (selectedFiles.length === 0) {
      toast.error('Please select at least one file', {
        position: toast.POSITION.TOP_LEFT,
      });
      return;
    }

    if (inputList.length === 0) {
      toast.error('Please enter at least one tag', {
        position: toast.POSITION.TOP_LEFT,
      });
      return;
    }

    if (description.trim() === '') {
      toast.error('Please enter a valid description', {
        position: toast.POSITION.TOP_LEFT,
      });
      return;
    }

    if (!isValidEmail(email)) {
      toast.error('Please enter a valid email address', {
        position: toast.POSITION.TOP_LEFT,
      });
      return;
    }

    selectedFiles.forEach((file, index) => {
      const formData = new FormData();
      formData.append('image', file);
      formData.append('description', description); 
      formData.append('email', email); 
      const tagsQueryParam = inputList.join(',');
      const apiUrl = `${IMAGE_UPLOAD_API_URL}${tagsQueryParam}&description=${description}&email=${email}`;
    
      axios
        .post(apiUrl, formData)
        .then((response) => {
          toast.success(`Image ${index + 1} uploaded successfully`, {
            position: toast.POSITION.TOP_LEFT,
          });
          setTimeout(() => {
            navigate('/');
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          console.error(`Error uploading image ${index + 1}:`, error);
          toast.error(`Error uploading image ${index + 1}. Please try again later`, {
            position: toast.POSITION.TOP_LEFT,
          });
        });
    });

    setInputList([]);
    setSelectedFiles([]);
    setImagePreviews([]);
    setDescription(''); 
    setEmail('');
  };

  return (
    <div className="image-upload-container">
      <div className="image-input">
        <input
          type="text"
          placeholder="Enter tags"
          className="input"
          value={inputText}
          onChange={handleInputChange}
          required
        />
        <button className="add-file" onClick={handleAddClick}>
          Add-tags
        </button>
      </div>
      <div className="input-list">
        {inputList.map((item, index) => (
          <div key={index} className="input-item">
            {item}
            <button
              className="Remove-file"
              onClick={() => handleRemoveClick(index)}
            ><XSquare />
            </button>
          </div>
        ))}
      </div>
      <div className="file-upload">
        <input type="file" onChange={handleFileChange} multiple />
        <button className="up-load" onClick={handleUpload}>
          Upload
        </button>
      </div>
      {imagePreviews.map((preview, index) => (
        <div key={index} className="image-preview">
          <img className="preview-image" src={preview} alt={`Preview ${index + 1}`} />
        </div>
      ))}
      <div className="description">
        <input
          type="text"
          placeholder="Image Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
        />
      </div>
      <div className="field">
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
    </div>
  );
};

export default ImageUpload;
