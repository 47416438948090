import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ImageDetail.scss';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ImageDetail = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedImage = JSON.parse(localStorage.getItem('selectedImage'));
    if (storedImage) {
      setSelectedImage(storedImage);
    }
  }, []);

  const bytesToMB = (bytes) => {
    if (bytes === 0) return '0 MB';
    const sizeInMB = bytes / (1024 * 1024);
    return sizeInMB.toFixed(2) + ' MB';
  };

  const addToCart = () => {
    if (selectedImage) {
      const existingCart = JSON.parse(localStorage.getItem('shoppingCart')) || [];
      const imageExists = existingCart.some(
        (item) =>
          item &&
          item._id === selectedImage._id &&
          item.imagedownloadableUrl === selectedImage.imagedownloadableUrl
      );

      if (imageExists) {
        toast.error('This image already exists in the shopping cart!', {
          position: toast.POSITION.TOP_LEFT,
        });
      } else {
        const updatedCart = [...existingCart, selectedImage];
        localStorage.setItem('shoppingCart', JSON.stringify(updatedCart));
        toast.success('Image added to cart', {
          position: toast.POSITION.TOP_LEFT,
        });
        navigate('/shopping');
      }
    }
  };

  return (
    <div className='images-cart'>
      {selectedImage && (
        <div className='images-detail'>
          <img className="image-url" src={selectedImage.imagedownloadableUrl} alt="Downloadable" />
          <div className="metadata-container">
            <p className="metadata-item">Size: {bytesToMB(selectedImage.metaData.size)}</p>
            <p className="metadata-item">Width: {selectedImage.metaData.width}</p>
            <p className="metadata-item">Height: {selectedImage.metaData.height}</p>
            <p className="metadata-item">Description:</p>
            <input className="metadata-text" value={selectedImage.description} readOnly />
            {Array.isArray(selectedImage.tags) && 
              <p className="sub">Tags: {selectedImage.tags.join(', ')}</p>
            }
          </div>
          <button className='cart' onClick={addToCart}>
            Add to Cart
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageDetail;
