import React, { useState, useEffect } from 'react';
import './CustomerLogin.scss';
import {Link} from 'react-router-dom';
import axios from 'axios';
import {CUSTOMER_LOGIN_API_URL } from './Constant';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  // const navigate = useNavigate();



  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  };

  
  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      email: email,
      password: password,
    };

    axios
      .post(CUSTOMER_LOGIN_API_URL, data, {
       
      })
      .then((response) => {
        const responseData = response.data;
        console.warn('Response Data:', responseData);

        if (responseData.success) {
          const customerauthKey = responseData.result.authKey;
          localStorage.setItem('customerauthKey', customerauthKey);

          localStorage.setItem('customerauthKey', customerauthKey);
          if (rememberMe) {
            localStorage.setItem('rememberedEmail', email);
            localStorage.setItem('rememberedPassword', password);
            localStorage.setItem('rememberedState', 'true');
          } else {
            localStorage.removeItem('rememberedEmail');
            localStorage.removeItem('rememberedPassword');
            localStorage.removeItem('rememberedState');
          }
          window.location.href = '/approval';
          toast.success('Login successful!', {
            position: toast.POSITION.TOP_LEFT,
          
          });
         

        } else {
          toast.error('Login failed. Please check your credentials.', {
          position: toast.POSITION.TOP_LEFT,
          });
        }
      }) 
      .catch((error) => {
        console.error('Error:', error);

        toast.error('An error occurred. Please try again later.', {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  useEffect(() => {
    const rememberedState = localStorage.getItem('rememberedState');
    if (rememberedState) {
      const rememberedEmail = localStorage.getItem('rememberedEmail');
      const rememberedPassword = localStorage.getItem('rememberedPassword');
      setEmail(rememberedEmail || '');
      setPassword(rememberedPassword || '');
      setRememberMe(true);
    }
  }, []);

  return (
    <div className="login">
      <div className="login-container">
        {/* <img src="download.png" alt="Logo" className="Cybox-logo" /> */}
        <div className="login-box">
          <h6>Customer Login</h6>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <div className="label-input-container">
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={handleChange}
                  className="input"
                  placeholder="Email Address"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="label-input-container">
                <input
                  type = "password"
                  id="password"
                  name="password"
                  value={password}
                  onChange={handleChange}
                  className="input"
                  placeholder="Password"
                />
              </div>
            </div>
            <div className="checkbox-container">
              <input
                type="checkbox"
                id="rememberMe"
                name="rememberMe"
                className="remember"
                checked={rememberMe}
                onChange={() => setRememberMe(!rememberMe)}
              />
              <label htmlFor="rememberMe">Remember me</label>
              <Link to = "/forgot-pass" className="forgot-password-link">
                Forgot Password
              </Link>
            </div>
            <button type="submit" className="cust-login">
              Submit
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
