import React, { useState, useEffect } from 'react';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import menus from '../../pages/menu';
import './styles.scss';
import logo from '../../assets/images/logo/logo.png';

const Header = () => {
  const [scroll, setScroll] = useState(false);
  const [authKey, setAuthKey] = useState(localStorage.getItem('authKey'));
  const [customerauthKey, setCustomerAuthKey] = useState(localStorage.getItem('customerauthKey'));
  const navigate = useNavigate();

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScroll(window.scrollY > 300);
    });
    return () => {
      setScroll({});
    };
  }, []);

  const [menuActive, setMenuActive] = useState(null);

  const handleMenuActive = () => {
    setMenuActive(!menuActive);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const handleDropdown = (index) => {
    setActiveIndex(index);

    // if (menus[index]?.name === 'Upload Picture' && !authKey && !customerauthKey) {
    //   navigate('/signup');
    // }
  };

  const handleSignOut = () => {
    if (authKey) {
      localStorage.removeItem('authKey');
      setAuthKey(null);
      navigate('/');
    } else if (customerauthKey) {
      localStorage.removeItem('customerauthKey');
      setCustomerAuthKey(null);
      navigate('/');
    }
  };

  const isAdmin = authKey !== null; 
  const isCustomer = customerauthKey !== null; 
 
  return (
    <header id="header_main" className={`header ${scroll ? 'is-fixed' : ''}`}>
      <div className="container">
        <div id="site-header-inner">
          <div className="header__logo">
            <NavLink to="/">
              <img  className='skin-logo'src={logo} alt="Crybox"  />
            </NavLink>
          </div>
          {(isAdmin || isCustomer) ? (
            <div className="sign-out-and-nav">
              <button className="sign-out-button" onClick={handleSignOut}>
                Sign Out
              </button>
              <nav
                id="main-nav"
                className={`main-nav ${menuActive ? 'active' : ''}`}
              >
                <ul id="menu-primary-menu" className="menu">
                  {menus.map((data, idx) => {
                    if (isAdmin) {
                      if (
                        // data.name === 'Home' ||
                        // data.name === 'Upload Picture' ||
                        data.name === 'Approval' 
                        // data.name === 'order Management' ||
                        // data.name === 'Cart'
                        // data.name === 'Edit'
                      ) {
                        return (
                          <li
                            key={idx}
                            onClick={() => handleDropdown(idx)}
                            className={`menu-item ${
                              data.namesub ? 'menu-item-has-children' : ''
                            } ${activeIndex === idx ? 'active' : ''}`}
                          >
                            <Link to={data.links}>{data.name}</Link>
                            {data.namesub && (
                              <ul className="sub-menu">
                                {data.namesub.map((submenu) => (
                                  <li key={submenu.id} className="menu-item">
                                    <NavLink to={submenu.links}>
                                      {submenu.sub}
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        );
                      }
                    } else if (isCustomer) {
                      if (
                        data.name === 'Home' ||
                        data.name === 'Upload Picture' ||
                        data.name === 'Cart'
                      ) {
                        return (
                          <li
                            key={idx}
                            onClick={() => handleDropdown(idx)}
                            className={`menu-item ${
                              data.namesub ? 'menu-item-has-children' : ''
                            } ${activeIndex === idx ? 'active' : ''}`}
                          >
                            <Link to={data.links}>{data.name}</Link>
                            {data.namesub && (
                              <ul className="sub-menu">
                                {data.namesub.map((submenu) => (
                                  <li key={submenu.id} className="menu-item">
                                    <NavLink to={submenu.links}>
                                      {submenu.sub}
                                    </NavLink>
                                  </li>
                                ))}
                              </ul>
                            )}
                          </li>
                        );
                      }
                    }
                    return null;
                  })}
                </ul>
              </nav>
            </div>
          ) : (
            <nav
              id="main-nav"
              className={`main-nav ${menuActive ? 'active' : ''}`}
            >
              <ul id="menu-primary-menu" className="menu">
                {menus.map((data, idx) => {
                  if (
                    data.name === 'Home' ||
                    data.name === 'Upload Picture' ||
                    data.name === 'ADMIN LOGIN' ||
                    data.name === 'Cart'
                  ) {
                    return (
                      <li
                        key={idx}
                        onClick={() => handleDropdown(idx)}
                        className={`menu-item ${
                          data.namesub ? 'menu-item-has-children' : ''
                        } ${activeIndex === idx ? 'active' : ''}`}
                      >
                        <Link to={data.links}>{data.name}</Link>
                        {data.namesub && (
                          <ul className="sub-menu">
                            {data.namesub.map((submenu) => (
                              <li key={submenu.id} className="menu-item">
                                <NavLink to={submenu.links}>{submenu.sub}</NavLink>
                              </li>
                            ))}
                          </ul>
                        )}
                      </li>
                    );
                  }
                  return null;
                })}
              </ul>
            </nav>
          )}
          <div
            className={`mobile-button ${menuActive ? 'active' : ''}`}
            onClick={handleMenuActive}
          >
            <span></span>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
