import React, { useState } from 'react';
import axios from 'axios';
import { CART_API_URL } from './Constant';
import { X } from 'react-bootstrap-icons';
import { toast } from 'react-toastify'; 
import 'react-toastify/dist/ReactToastify.css'; 

const DownloadPopup = ({ isOpen, onClose, onDownload, cartItems }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    const imageId = cartItems.map((item) => item.imageId);

    const payload = {
      name,
      email,
      message,
      imageId,
    };

    axios
      .post(CART_API_URL, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        console.log('API Response:', response.data);

        if (response.data.success) {
          // Show a success toast notification
          toast.success('Form submitted successfully!', {
            position: toast.POSITION.TOP_LEFT,
          });

          setFormSubmitted(true);
        } else {
          // Show an error toast notification
          toast.error('API Error: ' + response.data.message, {
            position: toast.POSITION.TOP_LEFT,
          });
        }

        onDownload();
      })
      .catch((error) => {
        console.error('API Error:', error);
       
        toast.error('API Error: ' + error.message, {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  const handleDownloadClick = (imageUrl, imageNumber) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = `image_${imageNumber}.jpg`;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className={`download-popup ${isOpen ? 'open' : ''}`}>
      <div className="popup-content">
        <h6>Contact-Form</h6>
        <button className="close-button" onClick={onClose}>
         <X />
        </button>
        {formSubmitted ? (
          <div className="download-buttons">
            {cartItems.map((item, index) => (
              <button className='do-button'
              
                key={item.imageId}
                onClick={() => handleDownloadClick(item.imagedownloadableUrl, index + 1)}
              >
              Download Image {index + 1}
              </button>
              
            ))}
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <div className="input-field">
              <input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <textarea
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
            <button className="sub-mit" type="submit">
              Submit
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default DownloadPopup;
