import React, { useState, useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
import './Approval.scss';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { IMAGES_API_URL, APPROVE_IMAGE_API_URL, REJECT_IMAGE_API_URL } from './Constant';

const Approval = () => {
  const [imagedata, setImageData] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectImage, setSelectImage] = useState(null);
  const [authKey, setAuthKey] = useState('');
  // const navigate = useNavigate();

  useEffect(() => {
    let authKey = localStorage.getItem('authKey');
    setAuthKey(authKey);

    const getImages = () => {
      const axiosConfig = {
        headers: {
          'Content-type': 'application/json;charset=UTF-8',
          'authKey': authKey, 
        },
      };

      axios
        .post(IMAGES_API_URL, {}, axiosConfig)
        .then((response) => {
          setImageData(response.data.result);
        })
        .catch((error) => {
          console.error(error);
        });
    };

    getImages();
  }, []);

  const handleSearch = (event) => {
    const newSearchTerm = event.target.value.toLowerCase();
    setSearchTerm(newSearchTerm);

    if (newSearchTerm === '') {
      
      getImages();
    } else {
      const axiosConfig = {
        headers: {
          'Content-type': 'application/json;charset=UTF-8',
          'authKey': authKey, 
        },
      };

      axios
        .post(IMAGES_API_URL, { search: newSearchTerm }, axiosConfig)
        .then((response) => {
          setImageData(response.data.result);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const getImages = () => {
    const axiosConfig = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        'authKey': authKey, 
      },
    };

    axios
      .post(IMAGES_API_URL, {}, axiosConfig)
      .then((response) => {
        setImageData(response.data.result);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const handleImageClick = (imagedownloadableUrl,tags,description,imageId) => {
    localStorage.setItem(
      'selectImage',
      JSON.stringify({ imagedownloadableUrl, tags, description,imageId})
    );
    setSelectImage(imagedownloadableUrl,tags,description,imageId);
    // navigate(`/edit`);
  };

  const handleApprove = (id) => {
    const axiosConfig = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        'authKey': authKey, 
      },
    };

    axios
      .post(APPROVE_IMAGE_API_URL, { id }, axiosConfig)
      .then((response) => {
        
        setImageData((prevImageData) => prevImageData.filter((image) => image._id !== id));

        toast.success('Image approved', {
          position: toast.POSITION.TOP_LEFT,
        });

        console.log('Image approved:', response.data);
      })
      .catch((error) => {
        console.error('Error approving image:', error);
        toast.error('Error approving image', {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  const handleReject = (id) => {
    const axiosConfig = {
      headers: {
        'Content-type': 'application/json;charset=UTF-8',
        'authKey': authKey, 
      },
    };

    axios
      .post(REJECT_IMAGE_API_URL, { id }, axiosConfig)
      .then((response) => {
        
        setImageData((prevImageData) => prevImageData.filter((image) => image._id !== id));

        toast.error('Image rejected', {
          position: toast.POSITION.TOP_LEFT,
        });

        console.log('Image rejected:', response.data);
      })
      .catch((error) => {
        console.error('Error rejecting image:', error);
        toast.error('Image rejected', {
          position: toast.POSITION.TOP_LEFT,
        });
      });
  };

  return (
    <body className='image-home'>
      <div className='search-bar'>
        <input
          className='search'
          type='search'
          placeholder='Search...'
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <div className='container'>
        {Array.isArray(imagedata) &&
          imagedata.map((image) => (
            <div className='images' key={image._id}>
              <img src={image.downloadableUrl} alt='Downloadable' onClick={() => handleImageClick(image.downloadableUrl,image.tags,image.description,image._id)} />
              {/* <p className='description-fr-images'> {image.description}</p>  */}
              
              <div className='approval-buttons'>
                <button className='approval' onClick={() => handleApprove(image._id)}>Approve</button>
                <button className='rejection' onClick={() => handleReject(image._id)}>Reject</button>
              </div>
            </div>
          ))}
      </div>
    </body>
  );
};

export default Approval;