import React, { useState, useEffect } from 'react';
import './HomeOne.scss';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { HOME_API_URL } from './Constant';

const HomeOne = () => {
const [imagedata, setImageData] = useState([]);
const [search, setSearch] = useState('');
const [currentPage, setCurrentPage] = useState(1);
const [selectedImage, setSelectedImage] = useState(null);
const [totalItems, setTotalItems] = useState('');
const itemsPerPage = 9;
const navigate = useNavigate();

useEffect(() => {
 fetchImages();
}, [currentPage, search]);

  const fetchImages = async () => {
    const response = await axios.post(HOME_API_URL, {
      start: (currentPage - 1) * itemsPerPage,
      search,
      
    });


    if (response.status === 200) {
      setImageData(response.data.result);
      setTotalItems(response.data.totalItems);
    } 
  };

  const handleSearch = (event) => {
    const newSearch = event.target.value.toLowerCase();
    setSearch(newSearch, () => {
      
      setCurrentPage(1);
      fetchImages();
    });
  };

  const handleImageClick = (imagedownloadableUrl, imageId, metaData,tags,description) => {
    localStorage.setItem(
      'selectedImage',
      JSON.stringify({ imagedownloadableUrl, imageId, metaData ,tags,description})
    );
    setSelectedImage(imagedownloadableUrl, metaData,tags,description);
    navigate(`/imagedetail`);
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxPageItems = 5;

    const minPage = Math.max(1, currentPage - Math.floor(maxPageItems / 2));
    const maxPage = Math.min(totalPages, minPage + maxPageItems - 1);

    for (let i = minPage; i <= maxPage; i++) {
      pageNumbers.push(i);
    }

    return (
      <>
        {currentPage > 1 && (
          <button className="pages" onClick={() => setCurrentPage(currentPage - 1)}>
            &laquo; 
          </button>
        )}
        {pageNumbers.map((pageNumber) => (
          <button
            className={`pages ${currentPage === pageNumber ? 'active' : ''}`}
            key={pageNumber}
            onClick={() => setCurrentPage(pageNumber)}
          >
            {pageNumber}
          </button>
        ))}
        {currentPage < totalPages && (
          <button className="pages" onClick={() => setCurrentPage(currentPage + 1)}>
             &raquo;
          </button>
        )}
      </>
    );
  };

  return (
    <body className="image-home">
      <div className="search-bar">
        <input
          className="search"
          type="search"
          placeholder="Search..."
          value={search}
          onChange={handleSearch}
        />
      </div>
      <div className="container">
        {imagedata.map((image) => (
          <div className="images" key={image._id}>
            <img
              src={image.downloadableUrl}
              alt="Downloadable"
              onClick={() =>
                handleImageClick(image.downloadableUrl, image._id, image.metaData,image.tags,image.description)
              }
            />
          </div>
        ))}
      </div>
      <div className="pagination">
        {renderPageNumbers()}
      </div>
    </body>
  );
};

export default HomeOne;
