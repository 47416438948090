// import { Cart } from 'react-bootstrap-icons';
const menus = [
    {
        id: 1,
        name: 'Home',
        links: '/',
       
    },
    {
        id: 2,
        name: 'Upload Picture',
        links: 'image-upload',
       

        
    },
    // {
    //     id: 3,
    //     name: 'Cart',
    //     links: '/shopping',
       
    // },
    {
        id:3,
        name:"Approval",
        links:'/approval'
    },
    {
        id:4,
        name:'order Management',
        links:'/order',
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'Order Management',
        //         links: '/order'
        //     },
        //     {
        //         id: 2,
        //         sub: 'Customer Management',
        //         links: '/customer'
        //     },
            

        // ]
    },
   
    
    {
        id: 5,
        name: 'ADMIN LOGIN',
        links: '/login',
        
        // namesub: [
        //     {
        //         id: 1,
        //         sub: 'ADMIN LOGIN',
        //         links: '/login'
        //     },
        //     {
        //         id: 2,
        //         sub: 'CUSTOMER LOGIN/SignUp?',
        //         links: '/signup'
        //     },
            

        // ],
    },
    {
        id: 6,
        name: 'Cart',
        links: '/shopping',
       
    },
    {
        id: 7,
        name: 'Edit',
        links: '/home-edit',
       
    },
    
    
]

export default menus;