import React, { useState, useEffect } from 'react';
import './CustomerManagement.scss';    
import { useNavigate } from 'react-router-dom';
import image1 from './images/public_Snake_River_(5mb).jpg';
import image2 from './images/public_wide-angle-shot-single-tree-growing-clouded-sky-during-sunset-surrounded-by-grass.jpg';
import image3 from './images/successful agricultural_1536x1536_40 (2).jpg';
import image4 from './images/s3Bucketoo0.5389757673365063-1658732797273 (1).png';
import image5 from './images/public_Snake_River_(5mb).jpg';
import image6 from './images/public_Snake_River_(5mb).jpg';
import image7 from './images/public_Snake_River_(5mb).jpg';
import image8 from './images/public_Snake_River_(5mb).jpg'; 

const CustomerManagement = () => {
    const navigate = useNavigate ();
 
  const [customerData, setCustomerData] = useState([
    {
      id: 1,
     
      image:  image1,
      tag: 'VIP',
      description: 'Large',
    },
    {
      id: 2,
      tag: 'Jane Smith',
      image:  image2,
      tag: 'Regular',
      description: 'Medium',
    },
    {
      id: 3,
      
      image:  image3,
      tag: 'VIP',
      description: 'Small',
    },
    {
        id: 4,
        
        image:  image4,
        tag: 'VIP',
        description: 'Small',
      },
      {
        id: 5,
        
        image:  image5,
        tag: 'VIP',
        description: 'Small',
      },
      {
        id:6,
        image: image6,
        tag:['landscape,nature'],
        description:'large',
    },
    {
        id:7,
        image: image7,
        tag:['space,water'],
        description:'large',
    },
    {
        id:8,
        image: image8,
        tag:['valley,mountain'],
        description:'images',
    }

  ]);
  const handleEditClick = (customerData) => {
    const editedCustomerData = {
      id: customerData.id,
      image: customerData.image,
      tag: customerData.tag,
      description: customerData.description,
    };

    localStorage.setItem('editCustomer', JSON.stringify(editedCustomerData));
    console.warn(editedCustomerData);
    navigate('/edit');
  };
  

  return (
    <div className="image-table-container">
      <table className="image-table">
        <thead>
          <tr>
            <th  className="table-header">S.no</th>
            <th  className="table-header">Image</th>
           
            <th  className="table-header">Tag</th>
            <th  className="table-header">Description</th>
            <th  className="table-header">Edit</th>
          </tr>
        </thead>
        <tbody>
          {customerData.map((customer, index) => (
            <tr key={customer.id}>
              <td className="table-cell">{index + 1}</td>
              <td className="table-cell">
                <img
                  src={customer.image}
                  alt={`Customer ${customer.name}`}
                  className="table-image"
                 
                />
              </td>
              {/* <td >{customer.name}</td> */}
              <td className="table-cell">{customer.tag}</td>
              <td className="table-cell">{customer.description}</td>
              <td>
              <button className="edit-button" onClick={() => handleEditClick(customer)}>Edit</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerManagement;
