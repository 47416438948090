import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IMAGE_UPDATE_API_URL, APPROVE_IMAGE_API_URL } from './Constant';
import './EditImage.scss';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import './EditPage.scss';

const Edit = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [editedTags, setEditedTags] = useState('');
  const [editedDescription, setEditedDescription] = useState('');
//   const [authKey, setAuthKey] = useState('');

  useEffect(() => {
    const storedImage = JSON.parse(localStorage.getItem('selectedImage'));
    setSelectedImage(storedImage);
    setEditedTags(storedImage.tags);
    setEditedDescription(storedImage.description);
    // let authKey = localStorage.getItem('authKey');
    // setAuthKey(authKey);
  }, []);

  const handleTagsChange = (event) => {
    setEditedTags(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    setEditedDescription(event.target.value);
  };

//   const sendUpdateRequest = () => {
//     const updatedImage = {
//       ...selectImage,
//       tags: editedTags,
//       description: editedDescription,
//     };

//     const payload = {
//       id: updatedImage.imageId,
//       tags: updatedImage.tags,
//       description: updatedImage.description,
//     };
//     const axiosConfig = {
//       headers: {
//         'Content-type': 'application/json;charset=UTF-8',
//         authKey,
//       },
//     };

//     axios
//       .post(IMAGE_UPDATE_API_URL, payload, axiosConfig)
//       .then((response) => {
//         console.log('API response:', response.data);
//       })
//       .catch((error) => {
//         console.error('API error:', error);
//       });
//   };

  



//   const handleUpdate = () => {
//     sendUpdateRequest();
//   };

  return (
    <div className='images-cart'>
      {selectedImage && (
        <div className='images-detail'>
          <img
            className='image-url-home'
            src={selectedImage.imagedownloadableUrl}
            alt='Downloadable'
          />
          <div className='input-change-container-home'>
            <input
              id='tags'
              className='tag-input'
              value={editedTags}
              onChange={handleTagsChange}
              placeholder='Tags'
            />
            <input
              id='description'
              className='description-input'
              value={editedDescription}
              onChange={handleDescriptionChange}
              placeholder='Description'
            />
          </div>
          <button className='update-home' >
            Update
          </button>
          {/* <button className='edit-image-approve' onClick={() => handleApprove(selectImage.imageId)}>Approve</button> */}
        </div>
      )}
    </div>
  );
};

export default Edit;
